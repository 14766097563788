.Sidebar-View {
  .container {
    max-width: 1100px;
  }

  .BodySection {
    width: 100%;
    float: left;
  }

  .wrapper {
    width: 230px;
    position: fixed;
    top: 70px;
    left: -1px;
    height: 100%;
    z-index: 99;
    // background: transparent linear-gradient(180deg, #EC743F 0%, #FF7D44 100%) 0% 0% no-repeat padding-box;
    background: #1f2b58;
    color: #fff;
    transition: all 0.5s;
  }

  .logo-container {
    padding: 13px 0px;
    text-align: center;
  }

  .logo_img {
    width: 62px;
    height: 62px;
  }

  .sidebar-container {
    padding: 18px 0px;
  }
  .nav-container {
    display: flex;
    align-items: center;
    margin-left: 15px;
    margin-right: 15px;
    padding: 5px 10px;
    border-bottom: 1px dashed #ffffff;
    border-radius: 2px;
  }
  .nav-icon {
    // width: 19px;
    height: 33px;
  }
  .text-hover {
    margin-bottom: 12px;
  }
  .text-hover :hover {
    background-color: #6374b1;
    border-radius: 6px;
    border-bottom: 1px dashed #6374b1;
  }

  .nav-link-container {
    padding: 0 12px;
    display: flex;
    align-items: center;
  }
  .track-img {
    display: flex;
    align-items: center;
  }
  .track-img img {
    margin-right: 8px;
    height: 28px;
  }
  .nav-link {
    background: transparent;
    border: none;
    color: #ffffff;
    padding: 0;
    font-size: 14px;
    line-height: 18px;
    // font-family: 'Mulish-ExtraBold';
  }

  .nav-link:focus {
    outline: none;
  }

  .decoration-none:hover {
    text-decoration: none;
  }
  .dropDown-menu:hover {
    background-color: #6374b1;
    // margin: 0px 15px;
    border-radius: 6px;
  }
  .decoration-none {
    border-bottom: 1px dashed #ffffff;
  }

  .sidebar-container .active {
    background: #6374b1;
  }

  .dropDown-menu {
    margin-left: 55px;
    margin-right: 10px;
    border-bottom: 1px dashed #ffffff;
    margin-bottom: 10px;
    padding-bottom: 6px;
  }
  .dropDown-menu .nav-link-container {
    padding: 8px 12px;
    padding-left: 54px;
    margin-bottom: 2px;
  }
  .dropdown-icon {
    width: 14px;
    height: 14px;
    margin-left: 8px;
    cursor: pointer;
  }

  .dropdown-container {
    // background-color: #F48D5F;
    margin-bottom: 2px;
  }

  .dropDown-menu .nav-link-container:hover {
    background-color: #6374b1 !important;
  }
  .menu-hide {
    padding: 0px !important;
  }
  .menu-hideactive {
    padding: 0px !important;
  }
  @media (max-width: 600px) {
    .nav-container {
    
      margin-left: 5px 3px;
      padding: 5px 3px;
    
    }
     .nav-link-container {
      padding: 0px 6px;
      display: flex;
      align-items: center;
  }
    .add-button {
      right: 11px;
      top: 6px;
      padding: 10px 4px;
    }
    .receipt-form-box {
      padding: 5px 15px 55px 15px
    }
    .nav-link {
      font-size: 11px
    };

  }
}
