@keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  
  .loading-spinner {
    width: 40px;
    height: 40px;
    border: 3px solid #f3f3f3;
    border-top: 3px solid #383636;
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
  
  }
  
  .spinner-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80vh;
    flex-direction: column;
    
  }
  .loading-text{
  
    color: #FFFFFF;
    margin-top: 6px;
  }
  
  .custom-loader .modal-content{
    background-color: transparent !important;
    border: none !important;
  }