.sucess-content {
  .sucess-modal {
    border-top: 3px solid #1f2b58;
    border-radius: 8px;
  }
  .modal-content {
    border-radius: 8px;
  }
  .addName-model {
    margin-top: 20px;
    font-size: 16px;
    font-family: "Mulish-SemiBold";
  }
  .input-lable {
    font-size: 16px;
    font-family: "Mulish-ExtraBold";
  }
  .submit-button {
    border-radius: 10px;
    background-color: #1f2b58;
    font-family: "Mulish-ExtraBold";
    border: none;
    text-align: right;
    font-size: 17px;
    padding: 6px 25px;
    color: white !important;
  }

  .password-input-container {
    position: relative;
  }

  .eye-icon {
    position: absolute;
    top: 3%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
  }
  .form-control {
    font-family: "Mulish-Regular";
    font-size: 15px;
    border-radius: 8px;
    border-bottom: 2px solid #d7d7d7;
    text-transform: uppercase;
  }

  .password-hideshow img {
    width: 20px;
    height: auto;
  }
  .crossbutton {
    cursor: pointer;
  }
  .receipt-form-box {
    margin: 20px 5px;
    background-color: white;
    padding: 5px 15px 34px 15px;
    border-radius: 8px;
    width: 100%;
  }

  .modal-dialog {
    max-width: 650px;
    margin: 1.75rem auto;
  }
  .react-datepicker-wrapper {
    display: inline;
  }

}
.custom-confirmationModal .modal-content {
  width: 75%;
  background-color: #fff;
  border-radius: 1.3rem;
  border-top: 5px solid #1f2b58;
}
.conform-text h5 {
  margin-bottom: 10px;
}
.confirmation-modal {
  text-align: center;
  margin: 40px 0px 20px 0px;
}
.conform-text {
  text-align: center;
  margin: 20px 0;
}
.btn-submit {
  background-color: #1f2b58;
  border: 3px solid #1f2b58;
  color: white;
  padding: 8px 20px;
  border-radius: 6px;
}
.btn-cancel {
  background-color: #d8d8d8;
  border: 3px solid #d8d8d8;
  color: rgb(0, 0, 0);
  padding: 8px 20px;
  border-radius: 6px;
}
