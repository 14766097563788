.HeaderSection {
    width: 100%;
    z-index: 999;
    height: 70px;
    align-items: center;
    display: flex;
    position: fixed;
    background-color: #FFFFFF;
  }
  
  .HeaderSection {
    .logo {
      width: 150px;
      height: auto;
    }
  
    .dropdown {
      display: inline-block;
      position: relative;
    }
  
    .dropdown-view {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      cursor: pointer;
    }
  
    .dropdown-view-contant {
      text-align: left;
      padding-right: 12px;
    }
  
    .name-view {
      font-size: 16px;
      color: #000000;
    }
  
    .admin-contant {
      font-size: 10px;
      color: #000000;
      opacity: 0.5;
    }
  
  
    .dropdown-icon-view {
      width: 10px;
      height: 7px;
    }
  
    .dropdown-menu {
      position: absolute;
      transform: translate3d(-10px, 49px, 0px);
      top: -6px !important;
      left: -84px !important;
    }
  
    .dropbtn {
      background-color: #E7E7EB;
      color: #323031;
      padding: 0px 12px;
      font-size: 14px;
      border: none;
      cursor: pointer;
      letter-spacing: 0;
      border-radius: 0;
      width: 190px;
      height: 36px;
      justify-content: space-between;
      align-items: center;
      display: flex;
      border-radius: 2px;
    }
  
    .homeuser {
      margin-top: -2px !important;
      margin-right: 10px !important;
    }
  
    .left {
      margin-left: 34px !important;
    }
  
    .dropdown-content {
      position: absolute;
      transform: translate3d(309px, 39px, 0px);
      top: 0px !important;
      left: 0px !important;
      will-change: transform;
    }
  

  
    .dropdown-content a {
      color: #141640;
      padding: 6px 8px !important;
      text-decoration: none;
      display: block;
      text-align: left;
      width: 182px;
      font-size: 14px;
      letter-spacing: -.22px;
    }
  
    .dropdown-menu {
      padding: 0 !important;
      margin: 0 !important;

    }
    .change-password {
      padding: 5px 0px;
      font-size: 13px;
      font-family: "Mulish-ExtraBold";
      cursor: pointer;
  }
  

  
    .logoutButton:hover {
      background-color: #7e7fa4;
      color: #ffffff;
      border: 0px;
      border-bottom: 3px solid #434466;
    }
  
    .pr-30 {
      padding-right: 30px;
    }
  
    .logout-icon {
      width: 14px;
      height: 14px;
      cursor: pointer;
    }
  
    .mobile-menu {
      display: none;
    }
  
    .close-menu {
      display: none;
    }
  
    .mobile-memu-img {
      width: 23px;
      height: auto;
    }
  
    .nav-link-container {
      cursor: pointer;
    }
  
    .logout-btn {
      border: none;
      background-color: #EFEFEF;
      padding: 6px 10px;
      font-size: 14px;
      line-height: 17px;
      width: 150px;
      text-align: left;
      border-radius: 2px;
      font-family: 'Mulish-Regular';
    }
  
    .dropdown-icon {
      width: 12px;
      height: 12px;
    }
  
    .user-icon {
    width: 21px;;
      height: auto;
    }
  
    .dropDown-menu {
      position: absolute;
      border: 1px solid #EFEFEF;
      background: #EFEFEF;
      margin-top: 1px;
      width: 100%;
      border-radius: 4px;
      box-shadow: 1px 1px 2px #eee;
    }
  
    .dropDown-menu-item {
      width: 100%;
      padding: 4px 12px;
      text-align: left !important;
      font-size: 14px;
      font-family: 'Mulish-Regular';
      border: none;
      background-color: #fff;
      border-radius: 2px;
      position: relative;
      
      right: 0;
      top: 0;
      // color: #fff;
  
  
    }

    .deletebutton {
      padding: 3px;
      width: 25px;
      position: absolute;
      top: 0;
      right: 0;
    }
    button.dropDown-menu-item.p-0 {
      font-size: 13px;
      font-family: "Mulish-ExtraBold";
      text-align: right;
  }
  
    .user-name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 92px;
      font-size: 14px;
      font-family: 'Mulish-Regular';
    }
    .userid{
      text-transform: capitalize;
      font-size: 13px;
      font-family: "Mulish-ExtraBold";
     
    }
  
  
    @media (max-width: 600px) {
      .icon-box-container {
        margin-bottom: 12px;
      }
  
      .work-icon-box-container {
        margin-bottom: 12px;
      }
  
      .workrow2 {
        margin-top: 0px;
      }
  
      .form-box {
        margin-top: 12px;
      }
  
      .main-heading-background {
        padding: 8px 16px;
      }
  
      .page-main-heading {
        font-size: 20px !important;
      }
  
      .icon-box-container {
        padding: 12px 18px;
      }
  
      .mobile-menu {
        display: block;
        background-color: white;
        width: 32px;
        border-radius: 100%;
        z-index: 99;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 1px 2px 3px #eee;
        transition: all 5s;
        height: 32px;
      }
  
  
      .close-btn {
        right: block !important;
      }
  
      .mobile-close-icon {
        width: 12px;
      }
  
      .logo {
        height: auto;
        width: 72px;
      }
      img.img-fluid.logo {
        display: none;
    }
    }
  }