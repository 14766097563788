.leaddetails-container {
  .dashboard-area {
    position: relative;
  }

  .dash-container {
    padding: 0px;
    margin-top: 0px;
  }

  .needs-validation {
    background-color: rgb(255 255 255);
    border-radius: 6px;
    padding: 30px 20px 30px 20px;
    border-top: 3px solid #1f2b58;
    margin: 30px 10px;
  }

  .input-lable {
    font-size: 16px;
    font-family: "Mulish-ExtraBold";
    margin-bottom: 5px;
    margin-top: 15px;
  }

  .form-control {
    font-family: "Mulish-Regular";
    font-size: 15px;
    border-radius: 8px;
    border-bottom: 2px solid #d7d7d7;
  }

  .submit-button {
    border-radius: 10px;
    background-color: #1f2b58;
    font-family: "Mulish-ExtraBold";
    border: none;
    text-align: right;
    font-size: 17px;
    padding: 6px 25px;
    color: white !important;
  }

  .filter-container {
    display: flex;
    gap: 10px;
    margin-top: 30px;
    margin-bottom: 30px;
    flex-wrap: wrap;
  }

  .disablebtn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
  }

  .calendar-container {
    position: relative;
  }
  .calendar-container1 {
    position: relative;
    // width: 287px;
  }

  .calendar-icon {
    position: absolute;
    top: 4px;
    right: 9px;
  }

  .btn-container {
    display: flex;
    gap: 20px;
  }

  .table-bordered {
    width: 100%;
  }

  @media (max-width: 600px) {
    .sub-heading {
      font-size: 20px;
      font-family: "Mulish-Bold";
      margin: 0px;
    }
    .submit-button {
      padding: 7px 23px;
      font-family: "Mulish-Bold";
      font-size: 11px;
    }
    .sub-mob-view {
      font-size: 21px !important;
    }
  }
}
