.Login-container {
  .LoginForm-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }
  .LoginForm {
    background-color: #1f2b58;
    opacity: 1;
    padding: 40px 30px 60px;
    width: 426px;
    position: absolute;
    margin: auto;
    box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
    border-radius: 8px;
  }

  .logo {
    width: 120px;
    height: auto;
  }
  .login-heading {
    margin-bottom: 34px;
  }
  .login-heading h2 {
    font-size: 24px;
    color: #fff;
    line-height: 27px;
    text-align: center;
    font-family: "Mulish-Bold" !important;
  }
  .login-header {
    margin-top: 40px;
  }
  .top-heading {
    margin-bottom: 34px;
    font-size: 30px;
    color: #242642;
    line-height: 27px;
  }
  .sub-heading {
    font-size: 16px;
    color: #242642;
    line-height: 15px;
    margin-bottom: 22px;
  }
  .login-input {
    border-bottom: 1px solid #707070 !important;
    border: none;
    text-align: center;
    color: #1f2b58;
    font-size: 16px;
    line-height: 15px;
    padding: 4px;
  }
  .login-input {
    outline: none;
  }
  .btn-container {
    width: 130px;
    height: 35px;
    border-radius: 100px;
    background-color: #1c1e3b;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 52px;
  }

  .resendotp-btn {
    background-color: #ffffff;
    border: 1px solid #1c1e3b;
  }
  .resendotp-btn .login-btn {
    color: #1c1e3b;
    font-size: 16px;
    line-height: 19px;
    background-color: #ffffff;
  }
  .logo-img {
    text-align: center;
  }
  @media (max-width: 600px) {
    .login-banner {
      display: none;
    }

    .mob-container {
      padding: 0px;
    }

    .login-box {
      width: 90%;
      margin: auto;
    }

    .LoginForm {
      background-color: #fff;
      opacity: 1;
      position: unset !important;
      box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
      justify-content: center;
      border-radius: 0px 40px 0px 40px !important;
      width: 390px;
      background: #fff;
      border-radius: 10px;
      overflow: hidden;
      padding: 50px 10px 33px 10px;
      box-shadow: 0 5px 10px 0px rgb(0 0 0 / 10%);
      -moz-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
      -webkit-box-shadow: 0 5px 10px 0px rgb(0 0 0 / 10%);
      -o-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
      -ms-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
      border-top: 4px solid #1f2b58;
      border-bottom: 4px solid #1f2b58;
    }

    .LoginForm-container {
      width: 100%;
      min-height: 92.2vh;
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      padding: 15px;
      overflow: hidden;
      // background-image: url("../../../src/assets/images/loginbg.webp");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }

    .logo {
      width: 134px;
      height: auto;
    }

    .login-header {
      margin-top: 15px;
    }

    .login-p {
      margin-bottom: 15px;
    }

    .top-heading {
      margin-top: 65px;
    }
    .login-heading {
      margin-top: 18px;
      margin-bottom: 18px;
    }
    .login-heading h2 {
      font-size: 18px;
      color: #1f2b58;
      line-height: 27px;
      text-align: center;
      font-family: "Mulish-Bold";
    }
  }
}
