.leaddetails-container {
    @media (max-width: 600px) {
        .add-button {
          right: 11px;
          top: 8px;
          padding: 10px 4px;
        }
        .receipt-form-box {
          padding: 6px 15px 60px 15px
        }
        .btn-container {
            gap: 7px;
        }
     
      
        .filter-container {
            justify-content: center};
     
      }
}