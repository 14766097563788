.otp-boxes{
    justify-content: center;
    align-items: center;
}
.otp-boxes input{
    border: none;
     border-bottom: 2px solid black;
}
.otp-boxes input:focus-visible{
    outline: none;
}

.mt-24{
    margin-top: 24px;
}

.login-agent-input{
    border-bottom: 1px solid #707070 !important;
    border: none;
    color: #1f2b58;
    font-size: 16px;
    line-height: 15px;
    padding: 4px;
    margin-bottom: 21px;
    width: 100%;
}
.login-agent-input{
    outline: none;
}
.login-p{
    margin-bottom: 25px;
}
.login-box{
    width: 70%;
    margin: auto;
    text-align: left;
}
.sumbit{
    border: 2px solid #ffffff;
}

.login{
    border: 2px solid #fff;
    background-color: #1f2b58;
}

.login :hover{
    background-color: #1f2b58;
}
.forget-pass{
    font-size: 14px;
    color: #1f2b58;
    line-height: 27px;
    text-align: center;
    font-family: 'Mulish-Regular';
    background-color: transparent;
    border: none;
    text-decoration: underline;
}

.forget-pass-container{
    position: absolute;
    bottom: 110px;
    right: 100px;
}
.password-hideshow{
    position: absolute;
    top: 5px;
    right: 20px;
}
.password-hideshow img{
    width: 20px;
    height: auto;
}