.leaddetails-container {
  .dashboard-area {
    position: relative;
  }

  .deletebutton {
    padding: 3px;
    width: 25px;
  }
  .addbutton {
    padding: 3px;
    width: 25px;
  }
  .disablebtn {
    border: none;
    background: #ffffff;
  }
  .thead {
    background-color: #1f2b58;
    color: #ffffff;
  }

  .data-table {
    background-color: #ffffff;
    border-radius: 8px;
  }
  .table-bordered td
   {
    border: 2px solid #8392a1;
    text-align: center;
    padding: 5px 0px ;
    font-size: 15px;
    font-family: "Mulish-SemiBold";
    text-transform: uppercase;
  }
  .table-bordered th{
    border: 2px solid #1f2b58;
    // padding-left: 5px;
    text-align: center;
    padding: 5px 0px ;

  }
  .add-hall-name {
    font-family: "Mulish-ExtraBold";
    font-size: 17px;
    color: white;
  }
  .sub-heading{
    font-size:26px;
    font-family: "Mulish-ExtraBold";
    margin: 0px;
  }

  .table-td{
    font-family: "Mulish-Regular";
    font-size: 15px;
  }

  .hall-header{
    background-color:#1f2b58 ;
    color: white;
    padding: 8px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
  }
}
