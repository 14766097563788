.leaddetails-container {
  .dashboard-area {
    position: relative;
  }

  .dash-container {
    padding: 0px;
    margin-top: 0px;
  }

  .add-button {
    position: absolute;
    right: 1px;
    top: 1px;
    border-radius: 4px;
    background-color: #1f2b58;
    font-family: "Mulish-ExtraBold";
    border: none;
    text-align: right;
    font-size: 13px;
    padding: 7px 8px;
    color: white !important;
  }
  .receipt-form-box {
    margin: 20px 16px;
    background-color: white;
    box-shadow: 0px 0px 6px 2px #dbdbdb;
    padding: 5px 15px 34px 15px;
    border-radius: 8px;
    width: 100%;
}
@media screen and (max-width: 1400px) {
  .action-res{
    width: 144px !important;
    min-width: 112px;
  }
  .action-response{
    width: 162px !important;
    min-width: 160px;
  }


}



}
