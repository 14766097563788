.leaddetails-container {
    .dashboard-area {
        position: relative;
    }

    .dash-container {
        padding: 0px;
        margin-top: 0px;
    }

    .filter-container {
        display: flex;
        gap: 42px;
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .calendar-container {
        position: relative;
    }

    .calendar-icon {
        position: absolute;
        top: 4px;
        right: 9px
    }

    .btn-container {
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
    }
    .listing-table {
        margin-top: 10px;
    }
}