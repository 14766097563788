 @font-face {
  font-family: 'Mulish-Bold';
  src: url('./assets/font-family/mulish/Mulish-Bold.woff2') format('woff2'),
    url('./assets/font-family/mulish/Mulish-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mulish-ExtraLight';
  src: url('./assets/font-family/mulish/Mulish-ExtraLight.woff2') format('woff2'),
    url('./assets/font-family/mulish/Mulish-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mulish-Regular';
  src: url('./assets/font-family/mulish/Mulish-Regular.woff2') format('woff2'),
    url('./assets/font-family/mulish/Mulish-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mulish-ExtraBold';
  src: url('./assets/font-family/mulish/Mulish-ExtraBold.woff2') format('woff2'),
    url('./assets/font-family/mulish/Mulish-ExtraBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mulish-Light';
  src: url('./assets/font-family/mulish/Mulish-Light.woff2') format('woff2'),
    url('./assets/font-family/mulish/Mulish-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mulish-SemiBold';
  src: url('./assets/font-family/mulish/Mulish-SemiBold.woff2') format('woff2'),
    url('./assets/font-family/mulish/Mulish-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}



body {
  margin: 0;
  background-color: #E7E7EB;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
label {
  margin: 0px;
  padding: 0px;
}

.progressBar-container {
  background-color: #FFFFFF;
  justify-content: space-between;
}

.bg-lightgrey {
  background-color: #F5F5F5;
}

.bg-white {
  background-color: #FFFFFF;
}

.RightArea {
  width: calc(100% - 230px);
  padding: 22px;
  min-height: 100vh;
  transition: all 0.3s;
  position: absolute;
  top: 40px;
  right: 0;
  background-color: #E7E7EB;
}


.flex-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.required-field{
  color: red;
}

.input-disabled{
  background-color: #e7e7e7 !important;
}
img.trash-icon {
  width: 14px;
  height: 14px;
}


.page-item.active .page-link{
    background-color: #1f2b58 !important;
    border-color: #dee2e6 !important;
}

.complated-view{
  padding-right: 10px !important;
}

.ComplateCheck-icon {
  position: absolute;
  top: 9px;
  right: 24px !important;
}

@media (max-width: 600px) {
  .Sidebar-View .wrapper {
    left: -100% !important;
  }

  .RightArea {
    width: 100% !important;
    padding: 32px 16px 20px;
  }

  .wrapper.active {
    left: 0px !important;
    transition: all 0.3s;
    width: 50% !important;
  }

}

